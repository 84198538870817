<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ attrs }">
      <p id="pjcaForm" v-bind="attrs" @click="openForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto">FORMULIR PJCA</span>
      </v-card-title>
      <v-form
        v-if="form != null"
        ref="formPjca"
        :disabled="loading"
        lazy-validation
        @submit.prevent="saveForm"
        style="position: relative"
      >
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Payment Request
                </p>

                <v-text-field
                  :value="detail.document_no"
                  readonly
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  PIC
                </p>

                <v-text-field
                  :value="form.employee.name"
                  readonly
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Perusahaan
                </p>

                <v-text-field
                  :value="form.company.name"
                  readonly
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
                <!-- <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete> -->
              </v-col>

              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Dari Deparment
                </p>

                <!-- <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete> -->
                <v-text-field
                  :value="form.department.name"
                  readonly
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Kepada Deparment
                </p>

                <v-autocomplete
                  v-model="form.to_department"
                  :items="dropdown.department"
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tanggal
                </p>

                <v-text-field
                  :value="form.date"
                  type="date"
                  name="startdate"
                  step="1"
                  readonly
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <v-col cols="12" md="3" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Disc. Global (%)
                </p>

                <v-text-field
                  v-model="form.global_discount_percent"
                  dense
                  outlined
                  type="number"
                  min="0"
                  style="font-size: 12px; height: 45px"
                  @change="globalPercentWatcher"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Discount Global
                </p>

                <v-text-field
                  v-model="form.global_discount"
                  type="number"
                  :min="0"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <!-- <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Pajak
                </p>

                <v-text-field
                  v-model="form.tax"
                  type="number"
                  :min="0"
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col> -->
            </v-row>

            <div style="margin-top: 30px">
              <v-data-table
                max-height="50vh"
                mobile-breakpoint="0"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                :headers="grHeaders"
                :items="form.pjca_detail"
                :items-per-page="10"
                item-key="id"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div style="width: 35px">
                    {{ index + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.product_code`]="{ item }">
                  <div style="width: 95px; font-size: 12px">
                    {{ item.product_code }}
                  </div>
                </template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          text-align: left;
                          width: 250px;
                          font-size: 12px;
                          padding: 0;
                        "
                      >
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}
                      </div>
                    </template>
                    <span>
                      {{
                        `${
                          item.product_code !== null
                            ? '(' + item.product_code + ')'
                            : ''
                        } ${item.product_name}`
                      }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.qty_request`]="{ item }">
                  <div style="width: 65px; font-size: 12px">
                    {{ item.qty_request }}
                  </div>
                </template>
                <template v-slot:[`item.qty_done`]="{ item }">
                  <div
                    style="
                      width: 95px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.qty_done"
                      type="number"
                      :min="0"
                      :max="item.qty_request"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.uom_name`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.uom_name }}
                  </div>
                </template>
                <template v-slot:[`item.price_done`]="{ item }">
                  <div
                    style="
                      width: 135px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.price_done"
                      type="number"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.discount_percent`]="{ item }">
                  <div
                    style="
                      width: 75px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.discount_percent"
                      :disabled="
                        item.discount_amount != null &&
                        item.discount_amount != 0
                      "
                      type="number"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.discount_amount`]="{ item }">
                  <div
                    style="
                      width: 135px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.discount_amount"
                      :disabled="
                        item.discount_percent != null &&
                        item.discount_percent != 0
                      "
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <template v-slot:[`item.tax_type`]="{ item }"
                  ><div
                    style="
                      width: 135px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-autocomplete
                      v-model="item.tax_type"
                      :items="dropdown.tax"
                      item-text="name"
                      item-value="id"
                      style="font-size: 12px; height: 45px"
                      return-id
                      dense
                      outlined
                      clearable
                    ></v-autocomplete>
                  </div>
                </template>
                <template v-slot:[`item.description`]="{ item }"
                  ><div
                    style="
                      width: 215px;
                      height: 45px;
                      font-size: 12px;
                      margin-top: 3px;
                    "
                  >
                    <v-text-field
                      v-model="item.description"
                      dense
                      outlined
                      style="margin: 0"
                    />
                  </div>
                </template>
                <!-- <template v-slot:[`item.price_total`]="{ item }">
                  <div style="width: 135; font-size: 12px">
                    {{ (item.qty_request * item.price) }}
                  </div>
                </template> -->
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              type="submit"
              :loading="loading"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              @click="closeDialog"
              :loading="loading"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
import moment from 'moment'
export default {
  name: 'FormGr',
  props: ['detail'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      hrsApi: buildType.apiURL('hrs'),
      loading: false,
      dialog: false,
      form: null,
      rules: {
        to_department: []
      },
      dropdown: {
        department: [],
        tax: [
          {
            id: 3,
            name: 'PPn 11% Inc'
          },
          {
            id: 4,
            name: 'PPn 11% Exc'
          },
          {
            id: 5,
            name: 'PPh 22 (0,3%)'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile']),
    grHeaders() {
      const arrHeaders = [
        {
          text: 'No',
          value: 'no',
          align: 'left'
          // sortable: true,
        },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty Sebelum',
          value: 'qty_request',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Rilis',
          value: 'qty_done',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Satuan',
          value: 'uom_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Harga Sebelum',
          value: 'price_request',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Harga Rilis',
          value: 'price_done',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Disc (%)',
          value: 'discount_percent',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Disc Amount',
          value: 'discount_amount',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Tax',
          value: 'tax_type',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Total',
          value: 'total',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Deskripsi',
          value: 'description',
          align: 'left',
          // sortable: true,
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    openForm() {
      console.log('hallo world', this.detail)
      const arrDetails = []
      const objForm = {
        act: 'add_batch_in_item',
        payment_request_id: this.detail.id,
        employee: {
          id: this.detail.employee_id,
          name: this.detail.employee_name
        },
        company: {
          id: this.detail.company_id,
          name: this.detail.company_name,
          code: this.detail.company_code
        },
        department: {
          id: this.detail.department_id,
          name: this.detail.department_name
        },
        to_department: null,
        global_discount: 0,
        global_discount_percent: 0,
        date: moment().format('YYYY-MM-DD')
      }
      if (this.detail.payment_request_detail != null) {
        for (let i = 0; i < this.detail.payment_request_detail.length; i++) {
          arrDetails.push({
            no: i + 1,
            pjca_detail_id: this.detail.payment_request_detail[i].id,
            product_id: this.detail.payment_request_detail[i].product_id,
            product_name: this.detail.payment_request_detail[i].product_name,
            product_code: this.detail.payment_request_detail[i].product_code,
            qty_request: this.detail.payment_request_detail[i].qty_request,
            price_request: this.detail.payment_request_detail[i].price_request,
            qty_done: this.detail.payment_request_detail[i].qty_request,
            price_done: this.detail.payment_request_detail[i].price_request,
            uom_id: this.detail.payment_request_detail[i].uom_id,
            uom_name: this.detail.payment_request_detail[i].uom_name,
            discount_percent:
              this.detail.payment_request_detail[i].discount_percent,
            discount_amount:
              this.detail.payment_request_detail[i].discount_amount,
            tax_type: this.detail.payment_request_detail[i].tax_type,
            prd_id: this.detail.payment_request_detail[i].prd_id,
            description: null
          })
        }
      }

      Object.assign(objForm, {
        pjca_detail: arrDetails
      })
      this.form = objForm
      this.getDropdownDepartment()
      setTimeout(() => {
        // this.startWatcher()
        this.dialog = true
      }, 300)
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    saveForm() {
      const self = this
      setTimeout(function () {
        if (self.$refs.formPjca.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        act: this.form.act,
        payment_request_id: this.form.payment_request_id,
        employee_id: this.form.employee.id,
        employee_name: this.form.employee.name,
        company_id: this.form.company.id,
        company_code: this.form.company.code,
        company_name: this.form.company.name,
        department_id: this.form.department.id,
        department_name: this.form.department.name,
        to_department_id:
          this.form.to_department != null ? this.form.to_department.id : null,
        to_department_name:
          this.form.to_department != null ? this.form.to_department.name : null,
        global_discount: this.form.global_discount,
        global_discount_percent: this.form.global_discount_percent,
        pjca_detail: this.form.pjca_detail
      }
      console.log(newForm)

      this.submitPjca(newForm)
    },
    async submitPjca(form) {
      this.loading = true
      await axios
        .post(`${this.proc}pjca/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code == '00') {
            const created_id = res.data.created_id
            console.log(created_id)
            this.showMsgDialog('success', res.data.status_msg, false)
            this.closeDialog()
            setTimeout(() => {
              // this.$store.commit('setFpbId', created_id)
              this.$router.push(`/e-catalogue/pjca/detail/${created_id}?idx=0`)
            }, 100)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    async getDropdownDepartment() {
      await axios
        .get(
          `${this.hrsApi}master/universal/department/dropdown?filter=[{"company_id":${this.form.company.id}}]`
        )
        .then((res) => {
          console.log(res)
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    }
  }
}
</script>
